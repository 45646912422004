export const increment = () => ({
  type: 'INCREMENT',
});

export const decrement = () => ({
  type: 'DECREMENT',
});

export const setProductData = (productData) => ({
  type: 'SET_PRODUCT_DATA',
  payload: productData,
});

export const removeProduct = (variationId) => ({
  type: 'REMOVE_PRODUCT',
  payload: variationId,
});

export const clearAllData = () => ({
  type: 'CLEAR_ALL_DATA',
});